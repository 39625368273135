import React, { useState } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Endpoints from "./components/services/Endpoints";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import logoNegativo from "./CDLlogoNegativo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";

import useAuth from "./hooks/useAuth";

/*
async function loginUser(credentials){
    return fetch('https://radcal.it/apireact/server_login_react.php', {
        method: 'POST',
        headers: {
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify(credentials)

    })
    .then(data => data.json())
}
*/

const ENDPOINT = Endpoints.getUrls();

export default function Home({ setToken }) {
  let navigate = useNavigate();

  const { login } = useAuth();

  const handleDemoClick = async () => {
    await login("demo@cdlservizipatronato.it", "@Patrizio70");
    navigate("/consulente/tipo_pratica", { replace: true });
  };

  const RenderHeader = () => {
    return (
      <div>
        <nav
          className="navbar navbar-primary"
          style={{ background: "#0059b3" }}
        >
          <div className="container-fluid text-white">
            <span className="navbar-brand mb-0 h5 text-white">
              CAF Nazionale Consulenti del Lavoro
            </span>
            <form className="d-flex">
              <Link to="/signin">
                <button className="btn text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="white"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>{" "}
                  Accedi all'area personale
                </button>
              </Link>
            </form>
          </div>
        </nav>

        <div>
          <div
            className="wrap-nav"
            style={{ height: "174px", background: "#0066cc" }}
          >
            <div
              className="logo"
              style={{
                backgroundColor: "#0066cc",
                height: "120px",
                marginLeft: "50px",
              }}
            >
              <div style={{ padding: "8px" }}>
                <img
                  src={logoNegativo}
                  alt="Logo"
                  style={{ height: "140px" }}
                />
              </div>
            </div>
          </div>

          <Navbar
            className="navbar navbar-expand-lg bg-dark"
            style={{ background: "#2266cc" }}
          >
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto"></Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        <Container fluid>
          <Row>
            <Container>
              <Row>
                <Col style={{ background: "#0c4a86" }}>
                  <div
                    className="card mt-20"
                    style={{
                      backgroundColor: "transparent",
                      marginTop: "20px",
                      textAlign: "center",
                      border: "none",
                    }}
                  >
                    <div className="card-body ">
                      <h1
                        className="no_toc text-white"
                        style={{ marginBottom: "4rem" }}
                      >
                        Vuoi accedere ai servizi di Patronato per CDL?
                      </h1>

                      <p className="card-text" />
                      <p
                        className="no_toc text-white"
                        style={{ marginBottom: "4rem" }}
                      >
                        {" "}
                        Registrarsi è semplicissimo: compila subito il form di
                        registrazione!
                      </p>

                      <Link to="/signin">
                        <button
                          type="button"
                          className="btn btn-success btn-icon btn-lg"
                          style={{ marginRight: "22px" }}
                        >
                          <span className="d-lg-block">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="currentColor"
                              className="bi bi-person-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              <path
                                fillRule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                              />
                            </svg>{" "}
                            Accedi all'area personale
                          </span>
                        </button>
                      </Link>

                      <Link to="/usersignup">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg"
                          style={{ marginRight: "22px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            className="bi bi-pencil"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>{" "}
                          Vai alla registrazione
                        </button>
                      </Link>

                      <button
                        type="button"
                        className="btn btn-primary btn-lg"
                        style={{
                          marginRight: "22px",
                          backgroundColor: "#a66300",
                        }}
                        onClick={handleDemoClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>{" "}
                        Guarda la demo
                      </button>

                      {/* <button
                        type="button"
                        className="btn  btn-md"
                        style={{
                          marginRight: "22px",
                          backgroundColor: "rgb(220 54 68)",
                          color: "white",
                          fontWeight: "bold",
                          height: "50px",
                          padding: "10px 20px 10px 20px",
                          fontSize: "20px",
                        }}
                        onClick={() => {
                          // Swal.fire({
                          //   title: "Il servizio sarà disponibile a breve",
                          //   icon: "info",
                          //   confirmButtonColor: "#3085d6",
                          //   cancelButtonColor: "#d33",
                          //   confirmButtonText: "Ok!",
                          // });
                          window.location.href = "/sos-avvocato/index.php";
                        }}
                      >
                        SOS AVVOCATO
                      </button> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    );
  };

  const RenderFooter = () => {
    return (
      <Container fluid style={{ background: "#001a33" }}>
        <Row style={{ minHeight: "116px" }}>
          <div className="mb-64 mt-64 text-white">
            <ul
              style={{
                listStyle: "inline",
                listStyleType: "none",
                paddingTop: "20px",
              }}
            >
              <li style={{ display: "inline", marginRight: "20px" }}>
                <a href="/policy">Privacy Policy</a>{" "}
              </li>
              <li style={{ display: "inline", marginRight: "20px" }}>
                <a href="/cookies">Cookie Policy</a>{" "}
              </li>
              <li style={{ display: "inline", marginRight: "20px" }}>
                <a href="/contatti">Contatti</a>{" "}
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    );
  };
  return (
    <div>
      <div>
        {RenderHeader()}

        <div
          className="wrap-nav"
          style={{ height: "174px", marginTop: "100px", background: "#003366" }}
        >
          <div
            className="logo"
            style={{
              backgroundColor: "##003366",
              height: "120px",
              marginLeft: "50px",
            }}
          >
            <div style={{ padding: "8px" }}>
              <img src={logoNegativo} alt="Logo" style={{ height: "140px" }} />
            </div>
          </div>
        </div>
      </div>
      {RenderFooter()}
    </div>
  );
}

Home.propTypes = {
  //   setToken: PropTypes.func.isRequired
};
