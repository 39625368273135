import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { useNavigate, Link } from "react-router-dom";
//import './navlink.css';
//import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Badge } from "react-bootstrap";
import logoNegativo from "../CDLlogoNegativo.svg";
import client from "../utils/client";
import Swal from "sweetalert2";
import useAuthHook from "../hooks/useAuth";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css";
import "./Tipopratica.css";
import "./cons.css";

import Endpoints from "../components/services/Endpoints";
const ENDPOINT = Endpoints.getUrls();

export default function ConsulenteHeader() {
  const [online, setOnline] = useState(0);

  const [inviate, setInviate] = useState(0);
  const [bozza, setBozza] = useState(0);
  const [sospese, setSospese] = useState(0);
  const [chiuse, setChiuse] = useState(0);
  const [annullate, setAnnullate] = useState(0);
  const [lavorazione, setLavorazione] = useState(0);
  const [active, setActive] = useState("default");

  const [userId, setUserId] = useState("");

  const [countMessages, setCountMessages] = useState(0);

  let navigate = useNavigate();
  const { isDemoUser } = useAuthHook();

  const getUserLogged = async () => {
    const consulenteClient = await JSON.parse(
      localStorage.getItem("currentUser")
    );

    const { _id, cognome, nome, email, role } = consulenteClient;

    setUserId(_id);
  };

  useEffect(() => {
    getUserLogged();
  }, []);

  const handleNamirial = (event) => {
    event.preventDefault();

    const doFetch = async () => {
      const response = await fetch(
        `${ENDPOINT}/users/getroles/id?_id=${userId}`
      );
      const userobj = await response.json();
      console.log(userobj);

      event.preventDefault();
      //     const tempPlayer = new FormData(event.target);

      const formDatax = new FormData();

      formDatax.append("famreg_sportello", userobj.userid);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/middleware/namirial_check.php`,
          formDatax
        )
        .then(function (response) {
          console.log(response);

          let responseDoc = new DOMParser().parseFromString(
            response.data,
            "application/xml"
          );
          let status =
            responseDoc.getElementsByTagName("status")[0].textContent;

          let successmess;
          let link;
          let errormess;

          if (status == "00") {
            successmess =
              responseDoc.getElementsByTagName("mess")[0].textContent;
            link = responseDoc.getElementsByTagName("url")[0].textContent;
            if (link) {
              window.location.replace(link, "_blank");
              //  navigate(`${element.value}`)
            }

            console.log(`successmess: ${successmess} - linkto: ${link}`);
          } else {
            errormess = responseDoc.getElementsByTagName("mess")[0].textContent;
            console.log(`errormess: ${errormess}`);
            navigate("/consulente/namirial-new");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    doFetch();
  };

  useEffect(() => {
    async function fetchData() {
      // You can await here

      //  console.log("TEST-RoleOp: ", localStorage.getItem('currentUser'));
      const cons = JSON.parse(localStorage.getItem("currentUser"));
      const id = cons._id;

      const result = await axios(
        `${ENDPOINT}/pratiche/count/consulente/id?_id=${id}`
      );

      let { inviate, bozza, lavorazione, sospese, chiuse, annullate } =
        result.data;
      setInviate(inviate);
      setBozza(bozza);
      setSospese(sospese);
      setChiuse(chiuse);
      setAnnullate(annullate);
      setLavorazione(lavorazione);
      // ...
    }
    fetchData();
  }, [inviate, bozza, sospese, chiuse, annullate]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    const getNumberOfMessages = async () => {
      const cons = JSON.parse(localStorage.getItem("currentUser"));
      const id = cons._id;
      const response = await axios.post(`${ENDPOINT}/direct/getmessages`, {
        from: id,
      });
      setCountMessages(response.data.length);
    };
    getNumberOfMessages();
  }, []);

  return (
    <main>
      <div>
        {/* <button onClick={notify}>Notify!</button>
        <ToastContainer />*/}
      </div>
      {/* 
      <Button variant="danger">
      utenti online <Badge bg="secondary">{ online }</Badge>
        <span className="visually-hidden">unread messages</span>
      </Button>
*/}
      <div
        className="logo"
        style={{ backgroundColor: "#0066cc", height: "180px" }}
      >
        <div className="header-consulente">
          <img
            src={logoNegativo}
            style={{ height: "160px", marginLeft: "1rem" }}
          />
          <div className="button-container">
            <button
              type="button"
              className="btn btn-primary btn-md"
              style={{
                backgroundColor: "white",
                color: "blue",
                marginRight: "22px",
                height: "50px",
                padding: "10px 20px 10px 20px",
                fontSize: "20px",
              }}
              disabled={isDemoUser}
              onClick={handleNamirial}
            >
              PUNTO PREVIDENZA
            </button>

            {<button
              type="button"
              disabled={isDemoUser}
              className="btn  btn-md"
              style={{
                marginRight: "22px",
                backgroundColor: "rgb(220 54 68)",
                color: "white",
                fontWeight: "bold",
                height: "50px",
                padding: "10px 20px 10px 20px",
                fontSize: "20px",
              }}
              onClick={() => {
                // Swal.fire({
                //   title: "Il servizio sarà disponibile a breve",
                //   icon: "info",
                //   confirmButtonColor: "#3085d6",
                //   cancelButtonColor: "#d33",
                //   confirmButtonText: "Ok!",
                // });
                if (!isDemoUser) {
                  window.location.href = "/sos-avvocato/index.php";
                  }
              }}
            >
              SOS AVVOCATO
            </button>}
          </div>
        </div>
      </div>
      {/* 

    <Navbar className='navbar navbar-expand-lg' style={{background: '#0d5bb0', fontSize: 'small'}} >
      <Container fluid >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" style={{marginLeft: '18px'}}>
       
            <NavLink to="/consulente/news" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>News</NavLink>
            <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Domande frequenti</Nav.Link>
            <NavLink to="/consulente/praticheconsulente" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Gestione Pratiche</NavLink>
            <Nav.Link to="#home" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Messaggi</Nav.Link>
            <Nav.Link to="#link" style={{color: 'white', borderLeft: '1px solid #F3F3F3', padding: '2px 31px 2px 31px'}}>Contatti</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
*/}
      {/*
<Container>
<nav className="nav nav-pills nav-fill">
<a className="nav-item nav-link active" href="#">Nuova Pratica</a>
<a className="nav-item nav-link" href="#">Inviate</a>
<a className="nav-item nav-link" href="#">Bozza</a>
<a className="nav-item nav-link" href="#">In lavorazione</a>
<a className="nav-item nav-link" href="#">Sospese</a>
<a className="nav-item nav-link " href="#">Chiuse</a>
<a className="nav-item nav-link " href="#">Annullate</a>
</nav>
</Container>
 */}
      <Container fluid style={{ minHeigth: "10px" }}>
        {" "}
      </Container>

      <Nav
        justify
        variant="tabs"
        defaultActiveKey="link-0"
        activeKey={active}
        onSelect={(selectedKey) => setActive(selectedKey)}
      >
        <Nav.Item>
          <NavLink
            eventkey="link-0"
            to="/consulente/tipo_pratica"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Nuova
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {"+"}
          </Badge>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            eventkey="link-1"
            to="/consulente/pratiche_inviate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Inviate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {inviate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-2"
            to="/consulente/pratiche_inbozza"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Bozza
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {bozza}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-3"
            to="/consulente/pratiche_lavorazione"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Lavorazione
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {lavorazione}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-4"
            to="/consulente/pratiche_sospese"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Sospese
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {sospese}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-5"
            to="/consulente/pratiche_chiuse"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Chiuse
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {chiuse}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-6"
            to="/consulente/pratiche_annullate"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Annullate
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            {annullate}
          </Badge>
        </Nav.Item>

        <Nav.Item>
          <NavLink
            eventkey="link-7"
            to="/consulente/archivio"
            className={({ isActive }) =>
              isActive ? "nav-link active my-nav-link" : "nav-link my-nav-link"
            }
          >
            Archivio
          </NavLink>
          <Badge bg="danger" style={{ display: "block", borderRadius: "0" }}>
            <i className="bi bi-archive"></i>
          </Badge>
        </Nav.Item>
      </Nav>

      {/*

        <Navbar bg="primary" variant="dark">
          <Container>

            <Nav className="me-auto">
              <span className='rim'>
              <NavLink to="/consulente/tipo_pratica" className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
              >Nuova Pratica</NavLink>
              </span>

              <span className='rim'>
              <NavLink to="/consulente/pratiche_inviate" className={({ isActive }) => (isActive ? ' Nav_link active' : 'Nav_link inactive')}
              >Inviate</NavLink> <Badge pill bg="danger">
              { inviate }</Badge></span>

              <span className='rim'>
              <NavLink to="/consulente/pratiche_inbozza"
              className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
              >Bozza</NavLink><Badge pill bg="danger">
              { bozza }
              </Badge></span>

              <span className='rim'>
            <NavLink to="/consulente/pratiche_lavorazione"
            className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
            >In lavorazione</NavLink><Badge pill bg="danger">
            { lavorazione }
          </Badge></span>

           <span className='rim'>
            <NavLink to="/consulente/pratiche_sospese"
            className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
            >Sospese</NavLink><Badge pill bg="danger">
            { sospese }
          </Badge></span>

          <span className='rim'>
          <NavLink to="/consulente/pratiche_chiuse"
          className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
          >Chiuse</NavLink><Badge pill bg="success">
          { chiuse }
        </Badge></span>

        <span className='rim'>
        <NavLink to="/consulente/pratiche_annullate"
        className={({ isActive }) => (isActive ? 'Nav_link active' : 'Nav_link inactive')}
        > Annullate</NavLink><Badge pill bg="danger">
        { annullate }
      </Badge></span>

            </Nav>
          </Container>
        </Navbar>

 */}
    </main>
  );
}
